import { TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import GenealogyApi from "../service/GenealogyApi";
import { Person } from "../domain/domain";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";

export function Searchfield() {
  const [persons, setPersons] = React.useState<Person[]>([]);
  const history = useHistory();

  function handleSelect(selectedPerson: Person | null) {
    history.push(`/persons/${selectedPerson?.id}`);
  }

  useEffect(() => {
    const genealogyApi = new GenealogyApi();
    genealogyApi.allPersons().then((p) => setPersons(p.data));
  }, []);

  return (
    <Autocomplete
      id="combo-box-demo"
      options={persons}
      getOptionLabel={(p) =>
        `${p.firstName} ${p.surname} ${p.birthday ? " - " + p.birthday : ""}`
      }
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      onChange={(event: any, selectedPerson: Person | null) =>
        handleSelect(selectedPerson)
      }
    />
  );
}
