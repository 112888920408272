import { EventFact, EventFactType } from "../domain/domain";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { EventCard } from "./EventCard";
import { EventFactForm } from "./EventFactForm";
import GenealogyApi from "../service/GenealogyApi";
import { sortEventsByDate, uuidv4 } from "../shared/shared";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventCard: { position: "relative" },
    fab: {
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

export const EventList = (props: {
  eventFacts: EventFact[] | undefined;
  personId: string | undefined;
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const [newEvent, setNewEvent] = React.useState<EventFact>({
    id: uuidv4(),
    eventFactType: EventFactType.death,
    personId: props.personId!,
    year: 0,
    month: 0,
    day: 0,
  });
  const [events, setEvents] = React.useState<EventFact[]>([]);

  useEffect(() => {
    if (props.eventFacts === undefined && events.length > 0) {
      setEvents([]);
    }

    if (props.eventFacts !== undefined) {
      setEvents(props.eventFacts);
    }
  }, [events, props.eventFacts]);

  function createNewEvent(): EventFact {
    return {
      id: uuidv4(),
      eventFactType: EventFactType.death,
      personId: props.personId!,
      year: 0,
      month: 0,
      day: 0,
      note: "",
      place: "",
    };
  }

  const addEvent = () => {
    const newEvent = createNewEvent();
    setNewEvent(newEvent);
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  }

  async function handleSubmit() {
    newEvent.personId = props.personId!;
    console.log(newEvent);
    const genealogyApi = new GenealogyApi();
    await genealogyApi.createEventFact(newEvent);
    events.push(newEvent);
    handleClose();
  }

  const deleteEvent = (id: string) => {
    console.log(`remove eventFact ${id}`);
    const index = events.findIndex((e) => e.id === id);
    if (index !== undefined && index > -1) {
      const eventFactsRemovedItem = events.splice(index, 1);
      if (!eventFactsRemovedItem) {
        throw new Error("EventFacts are undefined");
      }
      setEvents(eventFactsRemovedItem);
      console.log(
        `removed event with index ${index}; length now is ${props.eventFacts?.length}`
      );
    }
  };
  return (
    <div>
      <Card className={classes.eventCard}>
        <CardHeader title="Events" />
        <Fab color="primary" className={classes.fab} aria-label="add">
          <AddIcon onClick={addEvent} />
        </Fab>
        {events.sort(sortEventsByDate()).map((ef) => (
          <EventCard ef={ef} deleteEvent={deleteEvent} key={ef.id} />
        ))}
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add an event fill in this form
          </DialogContentText>
          <EventFactForm ef={newEvent} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            Cancel{" "}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {" "}
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
