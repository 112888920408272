import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import { PersonForm } from "./PersonForm";
import { Family, newPerson, Person } from "../domain/domain";
import GenealogyApi from "../service/GenealogyApi";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    formInput: {
      width: "30ch",
    },
  })
);

export const AddPartner = (props: {
  partner: Person;
  existingMarriages?: Family[];
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(() => false);
  const [newPartner] = React.useState<Person>(() => newPerson());
  const [selectedFamilyId, setSelectedFamilyId] = React.useState<string>(
    () => ""
  );

  function handleClose() {
    setOpen(false);
  }

  async function handleSubmit() {
    const genealogyApi = new GenealogyApi();
    const newPerson = await genealogyApi.createNewPerson(newPartner);
    if (selectedFamilyId !== "") {
      await genealogyApi.addParentToFamily(newPerson.id, selectedFamilyId);
    } else if (props.partner.id && newPerson.id) {
      let husbandId, wifeId;
      if (newPartner.gender === "female") {
        wifeId = newPartner.id;
        husbandId = props.partner.id;
      } else {
        wifeId = props.partner.id;
        husbandId = newPartner.id;
      }
      await genealogyApi.createFamily(husbandId, wifeId);
    } else {
      throw new Error("could not create relation between persons");
    }
  }

  const handleSelectedFamilyChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedFamilyId(event.target.value as string);
  };

  return (
    <Grid item>
      <Button
        variant="contained"
        color={"primary"}
        onClick={() => setOpen(true)}
      >
        Add Partner
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Partner</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <PersonForm person={newPartner} />
            <FormControl className={classes.formInput}>
              <InputLabel id="family-select-label">Select Family</InputLabel>
              {props.existingMarriages && props.existingMarriages.length > 0 ? (
                <Select
                  labelId="family-select-label"
                  id="family-select"
                  value={selectedFamilyId}
                  onChange={handleSelectedFamilyChange}
                >
                  {props.existingMarriages.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.id}
                    </MenuItem>
                  ))}
                </Select>
              ) : null}
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            Cancel{" "}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {" "}
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
