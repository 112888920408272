import { EventFact, Family, Person } from "../domain/domain";
import axios, { AxiosResponse } from "axios";
import { Session } from "./Session";
import qs from "querystring";
import { uuidv4 } from "../shared/shared";

export const DOMAIN =
  process.env.REACT_APP_STAGE === "dev"
    ? "http://localhost:3001"
    : "https://genealogy.pernpas.de";
process.env.TZ = "Europe/w";
console.log(`Domain is ${DOMAIN}`);

export default class GenealogyApi {
  static async login(username: string, password: string): Promise<string> {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const credentials = {
      username: username,
      password: password,
    };
    const response: any = await axios.post(
      DOMAIN + "/login",
      qs.stringify(credentials),
      config
    );
    return response.data.token;
  }

  async allPersons(): Promise<AxiosResponse<Person[]>> {
    return this.get<Person[]>("/v1/persons");
  }

  async allFamilies(): Promise<AxiosResponse<Family[]>> {
    return this.get<Family[]>("/v1/families");
  }

  async allRelationships(): Promise<AxiosResponse<{ nodes: []; links: [] }>> {
    return this.get<{ nodes: []; links: [] }>("/v1/relationships");
  }

  async findRelationshipsByPersonId(
    personId: string,
    depth: number
  ): Promise<AxiosResponse<{ nodes: []; links: [] }>> {
    return this.get<{ nodes: []; links: [] }>(
      `/v1/relationships?personId=${personId}&depth=${depth}`
    );
  }

  async findPathsBetweenPersons(
    personOne: string,
    personTwo: string
  ): Promise<AxiosResponse<Person[]>> {
    return this.get<Person[]>(
      `/v1/paths?personOne=${personOne}&personTwo=${personTwo}`
    );
  }

  findPersonById(personId: string): Promise<AxiosResponse<Person>> {
    return this.get<Person>(`/v1/persons/${personId}`);
  }

  findFamilyByPersonId(personId: string): Promise<AxiosResponse<Family[]>> {
    return this.get<Family[]>(`/v1/families?personId=${personId}`);
  }

  findFamilyById(familyId: string): Promise<AxiosResponse<Family>> {
    return this.get<Family>(`/v1/families/${familyId}`);
  }

  private async get<T>(urlContext: string): Promise<AxiosResponse<T>> {
    try {
      return axios.get(DOMAIN + urlContext, {
        headers: { token: Session.getUser().token },
      });
    } catch (e) {
      console.log("error: " + e);
      localStorage.clear();
      throw Error("Exception while fetching data");
    }
  }

  addChildToFamily(childId: string, familyId: string) {
    return GenealogyApi.post(
      null,
      `/v1/families/${familyId}/children/${childId}`
    );
  }

  async addParentToChild(parent: Person, child: Person) {
    return GenealogyApi.post(parent, `/v1/persons/${child.id}/parents`);
  }

  async addParentToFamily(parentId: string, familyId: string) {
    return GenealogyApi.post(
      null,
      `/v1/families/${familyId}/parent/${parentId}`
    );
  }

  createNewPerson(newPerson: Person): Promise<Person> {
    return GenealogyApi.post(newPerson, "/v1/persons");
  }

  createEventFact(newEvent: EventFact): Promise<EventFact> {
    return GenealogyApi.post(newEvent, "/v1/eventFacts");
  }

  updateEventFact(event: EventFact): Promise<EventFact> {
    return GenealogyApi.put(event, "/v1/eventFacts");
  }

  patchPerson(person: { id: string; note?: string }) {
    return GenealogyApi.patch(person, `/v1/persons/${person.id}`);
  }

  deleteEventFact(id: string): Promise<EventFact> {
    return GenealogyApi.delete(`/v1/eventFacts/${id}`);
  }

  private static async delete(path: string): Promise<any> {
    return await axios.delete(DOMAIN + path, {
      headers: { token: Session.getUser().token },
    });
  }

  private static async post<T>(entity: T, path: string): Promise<T> {
    const postResult = await axios.post(DOMAIN + path, entity, {
      headers: { token: Session.getUser().token },
    });
    return postResult.data as T;
  }

  private static async put<T>(entity: T, path: string): Promise<T> {
    const putResult = await axios.put(DOMAIN + path, entity, {
      headers: { token: Session.getUser().token },
    });
    return putResult.data as T;
  }

  private static async patch<T>(entity: T, path: string): Promise<T> {
    const patchResult = await axios.patch(DOMAIN + path, entity, {
      headers: { token: Session.getUser().token },
    });
    return patchResult.data as T;
  }

  addImage(imageFile: File, personId: string) {
    const formData = new FormData();
    formData.append("image", imageFile);
    formData.append("personId", personId);

    axios({
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        token: Session.getUser().token,
      },
      data: formData,
      url: DOMAIN + "/v1/images",
    })
      .then((resp) => {
        console.log("Upload successfull");
        console.log(resp);
      })
      .catch((err) => console.error(err));
  }

  async createFamily(husbandId: string, wifeId: string) {
    const familyId = uuidv4();

    return GenealogyApi.post({ husbandId, wifeId }, `/v1/families/${familyId}`);
  }
}
