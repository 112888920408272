import { Person } from "../../domain/domain";
import { Button, createStyles, Paper, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

export function PersonHighlight(props: {
  person: Person | undefined;
  title: string;
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4">
        {props.person
          ? props.person.firstName + " " + props.person.surname
          : ""}
      </Typography>
      <Typography variant="h4">
        {props.person ? props.person.birthday : ""}
      </Typography>
      <Typography variant="body1">
        {props.title}
        <Button
          color="primary"
          to={{ pathname: `/persons/${props.person?.id}` }}
          component={Link}
        >
          <LaunchIcon />
        </Button>
      </Typography>
    </Paper>
  );
}
