import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Family } from "../domain/domain";
import GenealogyApi from "../service/GenealogyApi";

export const FamilyInfo = ({
  match: { params },
}: RouteComponentProps<{ id: string }>) => {
  const [family, setFamily] = React.useState<Family>();

  useEffect(() => {
    const genealogyApi = new GenealogyApi();
    genealogyApi.findFamilyById(params.id).then((f) => {
      console.log(f.data);
      setFamily(f.data);
    });
  }, [params.id]);

  return (
    <div>
      <h1>Family - {params.id}</h1>
      <p>{family?.husbandId}</p>
    </div>
  );
};
