import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { newPerson, Person } from "../domain/domain";
import GenealogyApi from "../service/GenealogyApi";
import { PersonForm } from "./PersonForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "30ch",
      },
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(15),
      right: theme.spacing(2),
    },
  })
);

export const AddPerson = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(() => false);
  const [person, setPerson] = React.useState<Person>(() => newPerson());

  function handleOnClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    console.log(person);
    setOpen(false);
    new GenealogyApi().createNewPerson(person);
    setPerson(newPerson());
  }

  return (
    <Box>
      <Fab color="primary" className={classes.fab} aria-label="add">
        <AddIcon onClick={handleOnClick} />
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Person</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a person fill in this form
          </DialogContentText>
          <PersonForm person={person} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            Cancel{" "}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {" "}
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
