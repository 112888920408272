import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Person } from "../domain/domain";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    formInput: {
      width: "30ch",
    },
  })
);

export function PersonForm(props: { person: Person }) {
  const classes = useStyles();
  const [gender, setGender] = React.useState<string>(() => "FEMALE");

  const handleGenderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let gender = event.target.value as string;
    setGender(gender);
    props.person.gender = gender;
  };

  return (
    <Box border={1} borderColor="primary.main" borderRadius="borderRadius">
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="firstName"
          className={classes.formInput}
          label="First Name"
          onChange={(e) => (props.person.firstName = e.target.value)}
        />
        <TextField
          id="surname"
          className={classes.formInput}
          label="Surname"
          onChange={(e) => (props.person.surname = e.target.value)}
        />
        <FormControl className={classes.formInput}>
          <InputLabel id="demo-simple-select-label">Gender</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gender}
            onChange={handleGenderChange}
          >
            <MenuItem value={"MALE"}>male</MenuItem>
            <MenuItem value={"FEMALE"}>female</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          id="note"
          label="Note"
          onChange={(e) => (props.person.note = e.target.value)}
          multiline
          rows={5}
        />
      </form>
    </Box>
  );
}
