import { User } from "../domain/User";
import { Reject } from "../shared/Reject";

export class Session {
  static readonly userKeyInLocalStorage = "user";

  static store(user: User) {
    localStorage.setItem(this.userKeyInLocalStorage, JSON.stringify(user));
  }

  static isUserLoggedIn(): boolean {
    const user = localStorage.getItem(this.userKeyInLocalStorage);
    return user && JSON.parse(user);
  }

  static getUser(): User {
    const user = localStorage.getItem(this.userKeyInLocalStorage);
    Reject.ifUndefined(user, "User not found in localStorage");
    // @ts-ignore
    return JSON.parse(user) as User;
  }

  static closeSession() {
    Session.deleteAllCookies();
    localStorage.clear();
  }

  static deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
