import React, { ChangeEvent } from "react";
import GenealogyApi from "../service/GenealogyApi";
import { Button, Grid } from "@material-ui/core";

export const UploadImage = (props: { personId: string }) => {
  const startUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const genealogyApi = new GenealogyApi();
    genealogyApi.addImage(file!, props.personId);
  };

  return (
    <Grid item>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={startUpload}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" color={"primary"} component="span">
          Upload Image
        </Button>
      </label>
    </Grid>
  );
};
