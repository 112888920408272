import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import React from "react";
import { PersonForm } from "./PersonForm";
import { newPerson, Person } from "../domain/domain";
import GenealogyApi from "../service/GenealogyApi";

export const AddParent = (props: { child: Person }) => {
  const [open, setOpen] = React.useState<boolean>(() => false);
  const [parent] = React.useState<Person>(() => newPerson());

  function handleClose() {
    setOpen(false);
  }

  async function handleSubmit() {
    const genealogyApi = new GenealogyApi();
    const person = await genealogyApi.createNewPerson(parent);
    await genealogyApi.addParentToChild(person, props.child);
  }

  return (
    <Grid item>
      <Button
        variant="contained"
        color={"primary"}
        onClick={() => setOpen(true)}
      >
        Add Parent
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Parent</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <PersonForm person={parent} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            Cancel{" "}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {" "}
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
