import React from "react";
import { Button, Grid } from "@material-ui/core";

export const DeleteImage = (props: { mediaId: string }) => {
  return (
    <Grid item>
      <Button variant="contained" color={"primary"} component="span">
        Delete Image
      </Button>
    </Grid>
  );
};
