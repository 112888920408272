import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import React from "react";
import { EventFact } from "../domain/domain";
import { EventFactForm } from "./EventFactForm";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import GenealogyApi from "../service/GenealogyApi";
import { getDateString } from "../shared/shared";

export const EventCard = (props: {
  ef: EventFact;
  deleteEvent: (id: string) => void;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    new GenealogyApi().updateEventFact(props.ef);
    setOpen(false);
  };

  const handleDelete = () => {
    new GenealogyApi().deleteEventFact(props.ef.id);
    props.deleteEvent(props.ef.id);
  };
  return (
    <CardContent>
      <Typography variant="body1">
        {props.ef.eventFactType}: {getDateString(props.ef)}
        <Button>
          <EditIcon onClick={() => setOpen(true)} />
        </Button>
        <Button>
          <DeleteIcon onClick={handleDelete} />
        </Button>
      </Typography>

      <Typography variant="body2">
        {props.ef.place} {props.ef.note ? ", " + props.ef.note : ""}
      </Typography>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add an event fill in this form
          </DialogContentText>
          <EventFactForm ef={props.ef} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {" "}
            Cancel{" "}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {" "}
            Save{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </CardContent>
  );
};
