import { Family, Person } from "../domain/domain";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { AddChild } from "./AddChild";

export const FamilyShortCard = (props: {
  family: Family;
  person: Person | undefined;
}) => {
  const containsSelectedPerson = (c: Person) => c.id === props.person?.id;

  return (
    <Card>
      <CardHeader
        title={
          props.family.children?.findIndex(containsSelectedPerson) >= 0
            ? "Birth"
            : "Marriage"
        }
      />
      <CardContent>
        <AddChild family={props.family} />
        <Typography variant="body2" gutterBottom>
          ID: {props.family.id}
        </Typography>
        <Typography variant="body1">
          <Button
            color="primary"
            to={{ pathname: `/persons/${props.family.wife?.id}` }}
            component={Link}
          >
            Wife: {props.family.wife?.firstName} {props.family.wife?.surname}{" "}
            {props.family.wife?.birthday &&
              "(*" + props.family.wife?.birthday + ")"}
          </Button>
        </Typography>
        <Typography variant="body1">
          <Button
            color="primary"
            to={{ pathname: `/persons/${props.family.husband?.id}` }}
            component={Link}
          >
            Husband: {props.family.husband?.firstName}{" "}
            {props.family.husband?.surname}{" "}
            {props.family.husband?.birthday &&
              "(*" + props.family.husband?.birthday + ")"}
          </Button>
        </Typography>
        {props.family.children
          ?.sort((a, b) => {
            if (!a.birthday) return 0;
            if (!b.birthday) return 1;
            return a.birthday < b.birthday ? -1 : 1;
          })
          .map((c) => (
            <Typography variant="body1" key={c.id}>
              <Button
                color="primary"
                to={{ pathname: `/persons/${c?.id}` }}
                component={Link}
              >
                Child : {c.firstName} {c.surname}{" "}
                {c.birthday && "(*" + c.birthday + ")"}
              </Button>
            </Typography>
          ))}
      </CardContent>
    </Card>
  );
};
