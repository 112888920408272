import { Person } from "../../domain/domain";
import { Button, Paper, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import LaunchIcon from "@material-ui/icons/Launch";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

export function TodayBirthday(props: { persons: Person[] }) {
  const classes = useStyles();
  const [personsBirthdayToday, setPersonsBirthdayToday] = useState<Person[]>(
    []
  );

  useEffect(() => {
    const now = new Date();
    setPersonsBirthdayToday(
      props.persons.filter((p) =>
        p.birthday?.endsWith("-" + (now.getMonth() + 1) + "-" + now.getDate())
      )
    );
  }, [props.persons]);

  return (
    <Paper className={classes.paper}>
      {personsBirthdayToday.map((p) => (
        <div key={p.id}>
          <Typography variant="h4">{p.firstName + " " + p.surname}</Typography>
          <Typography variant="h4">{p.birthday}</Typography>
          <Typography variant="body1">
            Birthday
            <Button
              color="primary"
              to={{ pathname: `/persons/${p.id}` }}
              component={Link}
            >
              <LaunchIcon />
            </Button>
          </Typography>
        </div>
      ))}
    </Paper>
  );
}
