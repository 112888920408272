import React, { forwardRef, FunctionComponent, useEffect } from "react";

import MaterialTable, { Column, Icons } from "material-table";
import GenealogyApi from "../service/GenealogyApi";
import { Person } from "../domain/domain";

import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { ArrowUpward } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

export const PersonList: FunctionComponent = () => {
  const columns: Array<Column<Person>> = [
    { title: "First Name", field: "firstName" },
    { title: "Name", field: "surname" },
    {
      title: "Birthday",
      field: "birthday",
      render: (p) => <span>{p.birthday ?? ""}</span>,
    },
    {
      title: "",
      field: "id",
      render: (p) => (
        <div>
          <Button
            color="primary"
            to={{ pathname: `/persons/${p.id}` }}
            component={Link}
          >
            {" "}
            show Person
          </Button>
          <Button
            color="primary"
            to={{ pathname: `/relationshipGraph/${p.id}` }}
            component={Link}
          >
            {" "}
            show Relations{" "}
          </Button>
        </div>
      ),
    },
  ];

  const [persons, setPersons] = React.useState<Person[]>([]);

  useEffect(() => {
    const genealogyApi = new GenealogyApi();
    genealogyApi.allPersons().then((p) => setPersons(p.data));
  }, []);

  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  return (
    <div>
      <MaterialTable
        title="Persons"
        icons={tableIcons}
        columns={columns}
        data={persons}
        isLoading={persons.length === 0}
        options={{ pageSize: 25, pageSizeOptions: [10, 25, 50] }}
      />
    </div>
  );
};
