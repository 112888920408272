// screen if you're not yet authenticated.
import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

export interface PrivateRouteProps extends RouteProps {
  authenticationPath: string;
  isAuthenticated: boolean;
}

export class PrivateRoute extends Route<PrivateRouteProps> {
  public render() {
    let redirectPath: string = "";
    if (!this.props.isAuthenticated) {
      redirectPath = this.props.authenticationPath;
    }

    if (redirectPath) {
      const renderComponent = () => (
        <Redirect to={{ pathname: redirectPath }} />
      );
      return (
        <Route {...this.props} component={renderComponent} render={undefined} />
      );
    } else {
      return <Route {...this.props} />;
    }
  }
}
