import { Media } from "../domain/domain";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import { DOMAIN } from "../service/GenealogyApi";
import { Session } from "../service/Session";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper
    },
    imageList: {
      width: 500,
      height: 450
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)"
    }
  })
);

export function MediaList(props: { media: Media[] }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={180} className={classes.imageList}>
        {props.media.map((item) => {
          let imageSource = DOMAIN + "/pictures/" + item.filename + "?id=" + Session.getUser().token;
          return (
            <ImageListItem key={item.id}>
              <img
                src={imageSource}
                alt={item.filename}
              />
              <ImageListItemBar title={item.title} />
            </ImageListItem>
          );
        })}
      </ImageList>
    </div>
  );
}
