import { Person } from "../domain/domain";
import React, { useEffect } from "react";
import GenealogyApi from "../service/GenealogyApi";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const genealogyApi = new GenealogyApi();

interface FamilyTreePerson {
  id: string;
  name: string;
  parents: FamilyTreePerson[];
}

interface FamilyTree {
  rootPerson: Person;
  parents: FamilyTreePerson[];
}

const findParents = (
  rootId: string,
  id: string,
  relations: { nodes: []; links: [] }
): FamilyTreePerson[] => {
  const linksWithParents = relations.links.filter((l: any) => l.source === id);
  const personNode: any = relations.nodes.find((n: any) => n.id === id);
  if (linksWithParents.length === 0) {
    return [
      {
        id: id,
        name: personNode.firstname + " " + personNode.surname,
        parents: [],
      },
    ];
  }

  // look for parent and search their parent
  let parents: FamilyTreePerson[] = [];
  linksWithParents.forEach((l: any) => {
    const parentsOfParent = findParents(rootId, l.target, relations);
    parents.push(...parentsOfParent);
  });
  return rootId === id
    ? [...parents]
    : [
        {
          id: id,
          name: personNode.firstname + " " + personNode.surname,
          parents: parents,
        },
      ];
};

const useStyles = makeStyles({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400,
  },
});

export function FamilyTreeComponent(props: { rootPerson: Person | undefined }) {
  const classes = useStyles();
  const [familyTree, setFamilyTree] = React.useState<FamilyTree>();
  const [allPersonIds, setAllPersonIds] = React.useState<string[]>([]);

  useEffect(() => {
    if (props.rootPerson?.id) {
      genealogyApi
        .findRelationshipsByPersonId(props.rootPerson!.id, 4)
        .then((value) => {
          const rootId = props.rootPerson!.id;
          const parents = findParents(rootId, rootId, value.data);
          console.log(parents);
          const familyTree = {
            rootPerson: props.rootPerson!,
            parents: parents,
          };
          setFamilyTree(familyTree);
          const allIDs = value.data.nodes.map((n: any) => n.id as string);
          setAllPersonIds(allIDs);
        });
    }
  }, [props.rootPerson]);

  if (props.rootPerson === undefined) return <p>Loading Data</p>;

  function renderParents(parents: FamilyTreePerson[] | undefined): any {
    if (parents === undefined) {
      return <div>undefined</div>;
    }

    return parents.map((p) => {
      if (p.parents.length === 0) {
        return (
          <TreeItem
            key={p.id}
            nodeId={p.id}
            label={
              <Button
                color="primary"
                to={{ pathname: `/persons/${p.id}` }}
                component={Link}
              >
                {p.name}
              </Button>
            }
          />
        );
      } else {
        return (
          <TreeItem
            key={p.id}
            nodeId={p.id}
            label={
              <Button
                color="primary"
                to={{ pathname: `/persons/${p.id}` }}
                component={Link}
              >
                {p.name}
              </Button>
            }
          >
            {renderParents(p.parents)}
          </TreeItem>
        );
      }
    });
  }

  return (
    <React.Fragment>
      <h1>Tree</h1>
      {allPersonIds.length > 0 ? (
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          defaultExpanded={allPersonIds}
          // expanded={allPersonIds}
        >
          {renderParents(familyTree?.parents)}
        </TreeView>
      ) : (
        <div>Loading family tree</div>
      )}
    </React.Fragment>
  );
}
