import GenealogyApi from "../service/GenealogyApi";
import React, { useEffect } from "react";
import { Person } from "../domain/domain";
import { Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const genealogyApi = new GenealogyApi();

export function PathToPerson(props: {
  personOne: string;
  personTwo: string | undefined;
}) {
  const [personsPath, setPersonsPaths] = React.useState<Person[]>([]);

  useEffect(() => {
    if (props.personTwo) {
      genealogyApi
        .findPathsBetweenPersons(props.personOne, props.personTwo)
        .then((paths) => setPersonsPaths(paths.data));
    }
  }, [props.personOne, props.personTwo]);
  return (
    <div>
      {personsPath.length > 0 ? (
        <div>
          <h1>Path ({personsPath.length})</h1>
          {personsPath.map((p) => (
            <Typography variant="body1" key={p.id}>
              <Button
                color="primary"
                to={{ pathname: `/persons/${p.id}` }}
                component={Link}
              >
                {p.firstName} {p.surname}{" "}
                {p.birthday && "( *" + p.birthday + ")"}
              </Button>
            </Typography>
          ))}
        </div>
      ) : (
        <p>No path</p>
      )}
    </div>
  );
}
