import { uuidv4 } from "../shared/shared";

export interface Person {
  id: string;
  firstName?: string;
  surname?: string;
  gender: string;
  note?: string;
  media?: Media[];
  eventsFacts: EventFact[];
  birthday?: string;
}

export function newPerson(): Person {
  return {
    id: uuidv4(),
    gender: "FEMALE",
    eventsFacts: [],
  };
}

export interface Media {
  id: string;
  filename: string;
  note: string;
  title: string;
}

export interface EventFact {
  id: string;
  eventFactType: EventFactType;
  note?: string;
  place?: string;
  personId: string;
  year: number;
  month: number;
  day: number;
}

export enum EventFactType {
  birth = "BIRTH",
  occupation = "OCCUPATION",
  death = "DEATH",
  married = "MARRIED",
}

export interface Family {
  id: string;
  personId: string;
  husbandId: string;
  husband: Person;
  wifeId: string;
  wife: Person;
  children: Person[];
  eventsFacts: EventFact[];
}
