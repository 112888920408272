import React, { ChangeEvent, useEffect } from "react";
import { EventFact, EventFactType } from "../domain/domain";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    formInput: {
      width: "30ch",
    },
  })
);

export const EventFactForm = (props: { ef: EventFact }) => {
  const classes = useStyles();
  const [type, setType] = React.useState<EventFactType>(
    EventFactType.occupation
  );
  const [note, setNote] = React.useState("");
  const [place, setPlace] = React.useState("");
  const [year, setYear] = React.useState(0);
  const [month, setMonth] = React.useState(0);
  const [day, setDay] = React.useState(0);

  useEffect(() => {
    setType(props.ef.eventFactType);
    setNote(props.ef.note ? props.ef.note : "");
    setPlace(props.ef.place ? props.ef.place : "");
    setYear(props.ef.year ? props.ef.year : 0);
    setMonth(props.ef.month ? props.ef.month : 0);
    setDay(props.ef.day ? props.ef.day : 0);
  }, [
    props.ef.note,
    props.ef.eventFactType,
    props.ef.place,
    props.ef.year,
    props.ef.month,
    props.ef.day,
  ]);

  function handleEventTypeChange(event: React.ChangeEvent<{ value: unknown }>) {
    const newType = event.target.value as EventFactType;
    setType(newType);
    props.ef.eventFactType = newType;
  }

  function handleYearChange(event: ChangeEvent<{ value: unknown }>) {
    const newYear: number = event.target.value as number;
    setYear(newYear);
    props.ef.year = newYear;
  }

  function handleMonthChange(event: ChangeEvent<{ value: unknown }>) {
    const newMonth: number = event.target.value as number;
    setMonth(newMonth);
    props.ef.month = newMonth;
  }

  function handleDayChange(event: ChangeEvent<{ value: unknown }>) {
    const newDay: number = event.target.value as number;
    setYear(newDay);
    props.ef.day = newDay;
  }

  function handleNoteChange(event: ChangeEvent<{ value: unknown }>) {
    const newNote: string = event.target.value as string;
    setNote(newNote);
    props.ef.note = newNote;
  }

  function handlePlaceChange(event: ChangeEvent<{ value: unknown }>) {
    const newPlace: string = event.target.value as string;
    setPlace(newPlace);
    props.ef.place = newPlace;
  }

  const widthTextfield = 60;
  return (
    <Box border={1} borderColor="primary.main" borderRadius="borderRadius">
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="year"
          label="Year"
          onChange={handleYearChange}
          value={year}
          style={{ width: widthTextfield }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 4 }}
        />
        <TextField
          id="month"
          label="Month"
          onChange={handleMonthChange}
          value={month}
          style={{ width: widthTextfield }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 2 }}
        />
        <TextField
          id="day"
          label="Day"
          onChange={handleDayChange}
          value={day}
          style={{ width: widthTextfield }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*", maxLength: 2 }}
        />
        <FormControl className={classes.formInput}>
          <InputLabel id="demo-simple-select-label">Type of event</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            onChange={handleEventTypeChange}
          >
            {Object.keys(EventFactType).map((key) => (
              <MenuItem value={key.toUpperCase()} key={key}>
                {key.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          id="note"
          label="Note"
          onChange={handleNoteChange}
          value={note}
          multiline
          rows={5}
        />
        <TextField
          fullWidth
          id="place"
          label="Place"
          onChange={handlePlaceChange}
          value={place}
        />
      </form>
    </Box>
  );
};
