import { EventFact } from "../domain/domain";

export const uuidv4 = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const random = (Math.random() * 16) | 0;
    const number = random & 0x3;
    const v = c === "x" ? random : number | 0x8;
    return v.toString(16);
  });

export const getDateString = (e: EventFact) => {
  return `${e.year}-${e.month}-${e.day}`;
};

export const sortEventsByDate = () => {
  return (a: EventFact, b: EventFact) => {
    if (a.year === 0) return 1;
    if (b.year === 0) return -1;
    return a.year - b.year;
  };
};
