import React, { useEffect } from "react";
import GenealogyApi from "../service/GenealogyApi";
import { Person } from "../domain/domain";
import { createStyles, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { PersonHighlight } from "./widgets/PersonHighlight";
import { TodayBirthday } from "./widgets/TodayBirthday";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

export const Dashboard = () => {
  const classes = useStyles();

  const [persons, setPersons] = React.useState<Person[]>([]);
  const [youngestPerson, setYoungestPerson] = React.useState<Person>();
  const [oldestPerson, setOldestPerson] = React.useState<Person>();

  useEffect(() => {
    const genealogyApi = new GenealogyApi();
    genealogyApi.allPersons().then((p) => {
      setPersons(p.data);
      setYoungestPerson(
        p.data.reduce((min, p) => {
          if (!min.birthday) {
            return p;
          }

          if (!p.birthday) {
            return min;
          }

          if (p && min && p.birthday && min.birthday) {
            return p.birthday > min.birthday ? p : min;
          }
          return min;
        }, p.data[0])
      );

      setOldestPerson(
        p.data.reduce((max, p) => {
          if (!max.birthday || max.birthday === "0-0-0") {
            return p;
          }
          if (!p.birthday || p.birthday.startsWith("0-")) {
            return max;
          }
          if (p && max && p.birthday && max.birthday) {
            return p.birthday < max.birthday ? p : max;
          }
          return max;
        }, p.data[0])
      );
    });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <Typography variant="h2">{persons.length}</Typography>
            <Typography variant="body1">Persons</Typography>
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <PersonHighlight person={youngestPerson} title="youngest" />
        </Grid>
        <Grid item xs={3}>
          <PersonHighlight person={oldestPerson} title="oldest" />
        </Grid>
        <Grid item xs={3}>
          <TodayBirthday persons={persons} />
        </Grid>
      </Grid>
    </div>
  );
};
