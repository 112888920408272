import React, { useEffect } from "react";
import ForceGraph2D from "react-force-graph-2d";
import { Box } from "@material-ui/core";
import GenealogyApi from "../service/GenealogyApi";
import { RouteComponentProps } from "react-router-dom";

type PersonInfoProps = {
  id: string;
};

export const RelationshipGraph = ({
  match,
}: RouteComponentProps<PersonInfoProps>) => {
  const [relationships, setRelationships] = React.useState<{
    nodes: [];
    links: [];
  }>();
  const [depth, setDepth] = React.useState(3);

  useEffect(() => {
    const genealogyApi = new GenealogyApi();

    if (match.params.id) {
      genealogyApi
        .findRelationshipsByPersonId(match.params.id, depth)
        .then((value) => {
          setRelationships(value.data);
        });
    }
  }, [match.params.id, depth]);

  return (
    <Box>
      Depth:{" "}
      <input
        type="number"
        step="1"
        value={depth}
        onChange={(e) => setDepth(+e.target.value)}
      />
      <ForceGraph2D
        backgroundColor={"white"}
        nodeAutoColorBy="surname"
        linkColor={"100"}
        linkWidth={3}
        graphData={relationships}
        nodeCanvasObject={(node: any, ctx: any, globalScale: any) => {
          const label = node.firstname + " " + node.surname;
          const fontSize = 12 / globalScale;
          ctx.font = `${fontSize}px Sans-Serif`;
          const textWidth = ctx.measureText(label).width;
          const bckgDimensions = [textWidth, fontSize].map(
            (n) => n + fontSize * 0.2
          ); // some padding

          ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
          ctx.fillRect(
            node.x - bckgDimensions[0] / 2,
            node.y - bckgDimensions[1] / 2,
            ...bckgDimensions
          );

          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = node.color;
          ctx.fillText(label, node.x, node.y);
        }}
      />
    </Box>
  );
};
