import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Container } from "@material-ui/core";
import { Session } from "../service/Session";
import { useHistory } from "react-router-dom";
import GenealogyApi from "../service/GenealogyApi";

type LoginProperties = {
  setAuthenticated(value: boolean): void;
};

export function Login(props: LoginProperties) {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const buttonStyle = {
    marginTop: "10px",
  };
  const history = useHistory();

  const login = async () => {
    const token = await GenealogyApi.login(username, password);
    if (token) {
      Session.store({ userName: username, token: token });
      props.setAuthenticated(true);
      history.push("/");
    }
  };

  return (
    <Container maxWidth="xs">
      <form className="" noValidate autoComplete="off">
        <TextField
          fullWidth
          required
          id="standard-basic"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          fullWidth
          required
          id="standard-password-input"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={login}
          style={buttonStyle}
        >
          Login
        </Button>
      </form>
    </Container>
  );
}
