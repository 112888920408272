import React, { useEffect } from "react";
import { Family, Person } from "../domain/domain";
import GenealogyApi, { DOMAIN } from "../service/GenealogyApi";
import { RouteComponentProps } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography
} from "@material-ui/core";
import { Session } from "../service/Session";
import { AddParent } from "./AddParent";
import { FamilyShortCard } from "./FamilyShortCard";
import { EventList } from "./EventList";
import { UploadImage } from "./UploadImage";
import { AddPartner } from "./AddPartner";
import { DeleteImage } from "./DeleteImage";
import { FamilyTreeComponent } from "./FamilyTreeComponent";
import { MediaList } from "./MediaList";
import { Notes } from "./Notes";
import { PathToPerson } from "./PathToPerson";

type PersonInfoProps = {
  id: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: { maxWidth: 345 }
  })
);

export const PersonInfo = ({ match }: RouteComponentProps<PersonInfoProps>) => {
  const classes = useStyles();
  const [person, setPerson] = React.useState<Person>();
  const [families, setFamilies] = React.useState<Family[]>();

  useEffect(() => {
    const genealogyApi = new GenealogyApi();
    genealogyApi.findPersonById(match.params.id).then((p) => {
      setPerson(p.data);
      console.log(p.data);
    });
    genealogyApi
      .findFamilyByPersonId(match.params.id)
      .then((p) => setFamilies(p.data));
  }, [match.params.id]);

  function openInNewTab() {
    if (person?.media !== undefined && person?.media.length > 0) {
      window.open(
        DOMAIN + "/pictures/" + person?.media[0]?.filename + "?id=" + Session.getUser().token,
        "_blank"
      );
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid container spacing={2}>
        <AddParent child={person!} />
        <AddPartner
          partner={person!}
          existingMarriages={families?.filter(
            (f) => f.husbandId === person?.id || f.wifeId === person?.id
          )}
        />
        <UploadImage personId={person?.id!} />
        {person?.media !== undefined && person?.media.length > 0 ? (
          <DeleteImage mediaId={person?.media[0]?.id} />
        ) : (
          ""
        )}
      </Grid>
      <Grid item md={4}>
        <Card className={classes.root}>
          {person?.media !== undefined && person?.media.length > 0 ? (
            <CardMedia
              image={DOMAIN + "/pictures/" + person?.media[0]?.filename + "?id=" + Session.getUser().token}
              onClick={() => openInNewTab()}
              component="img"
            />
          ) : (
            <CardContent>
              <Typography variant="body1">No image available</Typography>
            </CardContent>
          )}
          <CardContent>
            <Typography variant="h4">
              {person?.firstName} {person?.surname}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={4}>
        <EventList eventFacts={person?.eventsFacts} personId={person?.id} />
      </Grid>
      <Grid item md={4}>
        <Notes person={person} />
      </Grid>
      {families?.map((f) => (
        <Grid item md={4} key={f.id}>
          <FamilyShortCard family={f} person={person} />
        </Grid>
      ))}
      <Grid item md={4}>
        <PathToPerson
          personOne={"22d9a5f3-8a03-456d-9f0f-ad001fb0412f"}
          personTwo={person?.id}
        />
      </Grid>
      <Grid item md={6}>
        <FamilyTreeComponent rootPerson={person} />
      </Grid>
      {person?.media !== undefined && person?.media.length > 1 ? (
        <Grid item md={4}>
          <MediaList media={person.media} />
        </Grid>
      ) : null}
    </Grid>
  );
};
