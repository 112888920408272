import React from "react";
import { Session } from "../service/Session";
import { Button, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Lock } from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";

type LogoutProps = {
  setAuthenticated(value: boolean): void;
};

export default function UserMenu(props: LogoutProps) {
  const userName = Session.isUserLoggedIn()
    ? Session.getUser().userName
    : "Not logged in";

  const handleLogout = () => {
    props.setAuthenticated(false);
    Session.closeSession();
  };

  return (
    <div>
      <Typography>User: {userName}</Typography>
      <Button
        onClick={() => {
          navigator.clipboard.writeText(Session.getUser().token);
        }}
      >
        Click to copy token
      </Button>
      <NavLink to="/login" onClick={handleLogout}>
        <ListItem button>
          <ListItemIcon>
            <Lock />
          </ListItemIcon>
          <ListItemText
            disableTypography={true}
            primary={
              <Typography variant="body1" color={"primary"}>
                {Session.isUserLoggedIn() ? "Logout" : "Login"}
              </Typography>
            }
          />
        </ListItem>
      </NavLink>
    </div>
  );
}
