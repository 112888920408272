import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { PrivateRoute } from "../PrivateRoute";
import { HashRouter, NavLink, Route } from "react-router-dom";
import { Login } from "./Login";
import { Session } from "../service/Session";
import Button from "@material-ui/core/Button";
import { CallSplit, Contacts, People } from "@material-ui/icons";
import UserMenu from "./UserMenu";
import { PersonInfo } from "./PersonInfo";
import { Hidden, useMediaQuery } from "@material-ui/core";
import { Searchfield } from "./Searchfield";
import { FamilyList } from "./FamilyList";
import { PersonList } from "./PersonList";
import { RelationshipGraph } from "./RelationshipGraph";
import { AllRelationshipsGraph } from "./AllRelationshipsGraph";
import { AddPerson } from "./AddPerson";
import { FamilyInfo } from "./FamilyInfo";
import { Dashboard } from "./Dashboard";
import HomeIcon from "@material-ui/icons/Home";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    title: {
      flexGrow: 1,
    },
  })
);

interface ResponsiveDrawerProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container?: any;
}

export default function AppDrawer(props: ResponsiveDrawerProps) {
  const isMobile = useMediaQuery("(min-width:600px)");
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [authenticated, setAuthenticated] = React.useState(() => {
    return Session.isUserLoggedIn();
  });

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      {authenticated ? (
        <List>
          <NavLink to="/">
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography variant="body1" color={"primary"}>
                    Dashboard
                  </Typography>
                }
              />
            </ListItem>
          </NavLink>
          <NavLink to="/allPersons">
            <ListItem button>
              <ListItemIcon>
                <Contacts />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography variant="body1" color={"primary"}>
                    All persons
                  </Typography>
                }
              />
            </ListItem>
          </NavLink>
          <NavLink to="/allFamilies">
            <ListItem button>
              <ListItemIcon>
                <People />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography variant="body1" color={"primary"}>
                    All Families
                  </Typography>
                }
              />
            </ListItem>
          </NavLink>
          <NavLink to="/relationshipGraph">
            <ListItem button>
              <ListItemIcon>
                <CallSplit />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                primary={
                  <Typography variant="body1" color={"primary"}>
                    All Relationships as graph
                  </Typography>
                }
              />
            </ListItem>
          </NavLink>
          <Divider />
          <UserMenu setAuthenticated={setAuthenticated} />
        </List>
      ) : null}
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HashRouter>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {isMobile && (
              <Typography variant="h6" className={classes.title} noWrap>
                {" "}
                Genealogy{" "}
              </Typography>
            )}
            {authenticated ? (
              <Box display="inherit">
                <Searchfield />
              </Box>
            ) : (
              <NavLink to="/Login">
                <Button>Login</Button>
              </NavLink>
            )}
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{ paper: classes.drawerPaper }}
              ModalProps={{ keepMounted: true }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{ paper: classes.drawerPaper }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <PrivateRoute
            authenticationPath="/login"
            path="/allPersons"
            isAuthenticated={authenticated}
            component={PersonList}
          />
          <PrivateRoute
            authenticationPath="/login"
            path="/persons/:id"
            isAuthenticated={authenticated}
            component={PersonInfo}
          />
          <PrivateRoute
            authenticationPath="/login"
            path="/allFamilies"
            isAuthenticated={authenticated}
            component={FamilyList}
          />
          <PrivateRoute
            authenticationPath="/login"
            path="/families/:id"
            isAuthenticated={authenticated}
            component={FamilyInfo}
          />
          <PrivateRoute
            authenticationPath="/login"
            path="/relationshipGraph/:id"
            isAuthenticated={authenticated}
            component={RelationshipGraph}
          />
          <PrivateRoute
            authenticationPath="/login"
            exact
            path="/relationshipGraph"
            isAuthenticated={authenticated}
            component={AllRelationshipsGraph}
          />
          <PrivateRoute
            authenticationPath="/login"
            exact
            path="/"
            isAuthenticated={authenticated}
            component={Dashboard}
          />
          <Route
            path="/login"
            component={() => <Login setAuthenticated={setAuthenticated} />}
          />
          <AddPerson />
        </main>
      </HashRouter>
    </div>
  );
}
