import React, { useEffect } from "react";
import { Card, CardContent, CardHeader, TextField } from "@material-ui/core";
import { Person } from "../domain/domain";
import GenealogyApi from "../service/GenealogyApi";

const genealogyApi = new GenealogyApi();

export function Notes(props: { person: Person | undefined }) {
  const noteValue = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (noteValue.current) {
      noteValue.current.value =
        props.person?.note === undefined ? "" : props.person.note;
    }
  }, [props.person]);

  const updateNote = (
    focusEvent: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (noteValue.current) {
      console.log(`Note: ${noteValue.current.value}`);
      genealogyApi
        .patchPerson({
          id: props.person!.id,
          note: noteValue.current.value,
        })
        .then((r) => console.log("Note persisted"));
    }
  };

  return (
    <Card>
      <CardHeader title="Notes" />
      <CardContent>
        <TextField
          id="outlined-multiline-static"
          multiline
          minRows="12"
          defaultValue={props.person?.note}
          variant="outlined"
          size="medium"
          inputRef={noteValue}
          onBlur={updateNote}
          fullWidth
        />
      </CardContent>
    </Card>
  );
}
